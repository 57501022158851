/* TAGS */
/* ==== */
html,
body {
  background: #0d0823;
  background: linear-gradient(0deg, rgba(13,8,35,1) 1%, rgba(13,8,35,1) 33%, rgba(15, 15, 25,1) 100%);
  color: #ccc;
  font-family: "Courier New 11", "Courier Prime", monospace, sans-serif;
  margin: 0;  min-height: 100vh;
  min-width: 100vw;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6,
div,
a,
p {
  font-family: "Courier New 11", "Courier Prime", monospace, sans-serif;
}

a,
a:hover,
a:visited {
  color: #7563c9;
  text-decoration: none;
}

code {
  background-color: #000000;
  border-radius: 7px;
  font-family: "Courier New 11", "Courier Prime", monospace, source-code-pro, sans-serif;
  padding: 20px;
}

img {
  border-radius: 7px;
  vertical-align: middle;
}

ul,
li {
  padding: 0;
  margin: 0;
}

li {
  line-height: 1em;
  list-style-type: none;
  padding: 3px;
}

pre {
  font-size: 5px;
  margin: 0;
  padding: 0;
}

strong {
  color: #ffffff;
}


/* ANIMATION */
/* ========= */
.delay1 {
  --animate-delay: 1s;
}
.delay2 {
  --animate-delay: 2s;
}
.delay3 {
  --animate-delay: 3s;
}
.delay4 {
  --animate-delay: 4s;
}
.delay5 {
  --animate-delay: 5s;
}

.fadeIn {
  animation: fadeIn;
  animation-duration: 0.5s; 
}
.fadeIn1 {
  animation: fadeIn;
  animation-duration: 1s; 
}
.fadeIn2 {
  animation: fadeIn;
  animation-duration: 2s; 
}
.fadeIn3 {
  animation: fadeIn;
  animation-duration: 3s; 
}
.fadeIn4 {
  animation: fadeIn;
  animation-duration: 4s; 
}
.fadeIn5 {
  animation: fadeIn;
  animation-duration: 5s; 
}

.fadeOut {
  animation: fadeOut;
  animation-duration: 1s;
}

@-webkit-keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


/* CONTENT */
/* ======= */
.wrapper{
  margin: 0;
  padding: 0;
}

/* 404 
.four-oh-four {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  background-color: #0d0823;
  transition: opacity 300ms ease-out;
  background-position: center center;
  background-repeat: no-repeat;
}

.four-oh-four .dJAX_internal {
  opacity: 0;
}
*/

.terminal {
  display: flex;
  flex-direction: column;
  margin: 0px 25px;
  padding: 0px;
  width: auto;
}
/* INTRODUCTION TEXT */
/* ================= */
.text {
  color: #aaa;
  display: block;
  /*  font-family: "Courier New 11", "Courier Prime", monospace; */
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.2em;
  margin: 0 0 1.1em 0;
  max-width: 520px;
  padding: 0;
}

.text-shortener {
  max-width: 500px;
}

.datetime {
  margin-top: 20px;
}

#p5 {
  display: none;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

/* PROMPT % CHARACTER */
/* ================== */
.prompt-line {
  display: block;
}

.prompt-buffer,
.prompt-line-buffer {
  padding-bottom: 20px;
}

.prompt-character {
  color: #ccc;
  content: ">  ";
  display: inline-block;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1.1em;
  /* white-space: pre-wrap; */
  width: 20px;
}

.prompt {
  color: rgba(247, 179, 247, 0.75);
  display: inline-block;
  /* font-family: "Courier New 11", "Courier Prime", monospace, sans-serif; */
  font-size: 0.9em;
  font-weight: normal;
  letter-spacing: 0.05em;
  line-height: 1.1em;
  margin: 0;
  padding: 0;
  /*
  text-shadow: 0 0 2px rgba(247, 179, 247, 0.75);
  text-transform: lowercase;
  white-space: pre-wrap;
  */
}

.prompt-new {
  color: rgba(247, 179, 247, 0.75);
  display: inline-block;
  /* font-family: "Courier New 11", "Courier Prime", monospace, sans-serif; */
  font-size: 0.9em;
  font-weight: normal;
  /* letter-spacing: 0.15em;*/
  line-height: 1.1em;
  margin: 0;
  padding: 0;
  position: relative;
  /* text-shadow: 0 0 2px rgba(247, 179, 247, 0.75);*/
  top: -1.3em;
  /*  white-space: pre-wrap; */
}

.here {
  background-color: transparent;
  content: "> ";
  left: 0;
  margin-bottom: 10px;
  opacity: 0;
  position: absolute;
  text-transform: lowercase;
  width: 100%;
  z-index: 10;
}

.new-output {
  content: "> "; 
  display: inline-block;
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.1em;
  margin: 0 0 0 20px;
  padding: 0;
  /* white-space: pre-wrap; */
  width: 100%;
}


/* CURSOR */
/* ====== */
.new-output:after {
  animation: cursor-blink 1.5s steps(1) infinite;
  background: rgb(255, 125, 255);
  box-shadow: 1px 1px 1px rgba(247, 179, 247, 0.75),
    -1px -1px 1px rgba(247, 179, 247, 0.75),
    1px -1px 1px rgba(247, 179, 247, 0.75),
    -1px 1px 1px rgba(247, 179, 247, 0.75);
  content: " ";
  display: inline-block;
  font-size: 0.9em;
  font-weight: normal;
  height: 1em;
  line-height: 1.2em;
  margin-left: 2px;
  position: relative;
  top: 2px;
  width: 0.5em;
  -webkit-animation: cursor-blink 1.5s steps(1) infinite;
  -moz-animation: cursor-blink 1.5s steps(1) infinite;
}

.result {
  animation: fadeIn;
  animation-duration: 1s;
  color: #ccc;
  display: block;
  font-size: 0.9em;
  font-weight: normal;
  margin: 10px 0 20px 20px;
}


/* HELPERS */
/* ======= */
.container {
  display: flex;
  flex-direction: row;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 0 0;
  white-space: nowrap;
  width: 100%;
}

.resume,
.project,
.social,
.why {
  align-items: left;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 7px;
  display: block;
  float: left;
  max-width: 200px;
  padding: 10px;
  text-shadow: none;
  width: fit-content;
}

.notabutton {
  background: transparent;
  border: none;
  color: inherit;
  cursor:pointer;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.lightbox-trigger {
  display: inline-flex;
  float: right;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: fit-content;
}

.scroll-tip {
  display: none;
}

@media only screen and (max-width: 700px) {
  .scroll-tip {
    animation: bounceInRight;
    animation-duration: 2s;
    display: none;
    display: block;
    font-size: 0.8em;
    margin: 0 0 20px 20px;
    position: relative;
    top: -20px;
  }
}

span.icon {
  font-size: 40px;
  line-height: 40px;
}

.icon {
  height: 40px;
  width: 40px;
}

.figma {
  height: 14px;
}

.widow {
  white-space: pre;
}

/* HELP TIP */
/* ========
a.tip:hover {
  cursor: help;
  position: relative
}

a.tip span {
  display: none
}

a.tip:hover span {
  border: #000 1px dotted;
  color:#ccc;
  display: block;
  left: 0px;
  margin: 10px;
  padding: 5px 20px 5px 5px;
  position: absolute;
  text-decoration: none;
  top: 10px;
  width: 250px;
  z-index: 100;
}
*/


/* SCROLL BARS */
/* =========== */
* {
  scrollbar-color: #0d0823 #0d0823;
  scrollbar-width: 0px !important; /* thin; */
}
*::-webkit-scrollbar {
  width: 0px !important;
}

*::-webkit-scrollbar-track {
  background: #0d0823; /* color of the tracking area */
}
*::-webkit-scrollbar-thumb {
  background-color: #0d0823; /* color of the scroll thumb */
  border: none;
}


/* COLORS */
/* ====== */

.hotpink {
  color: #ff00ff;
}


.bubblegum {
  color: #f7b3f7;
}


.pink {
  color: rgba(247, 179, 247, 0.75);
}

.red {
  color: #dd2f24;
}

.dark-red {
  color: #970002;
}

.orange,
a.orange,
a.orange:visited {
  color: #d27800
}

.orange:hover,
a.orange:hover {
  color: #ff7800
}

.yellow {
  color: #edf01d
}

.dark-yellow {
  color: #b1b329;
}

.green {
  color: #42c42a;
}

.blue {
  color: #40bfcb;
}

.purple {
  color: #7563c9;
}

/* MOBILE ALERT */
/* ============ */
.mobileAlert {
  display: none;
}

@media only screen and (max-width: 700px) {
  .mobileAlert {
    background: rgb(13,8,35);
    background: linear-gradient(0deg, rgba(13,8,35,1) 1%, rgba(13,8,35,1) 33%, rgba(15, 15, 25,1) 100%);
    color: #ccc;
    display: block;
    height: 100vh;
    margin: 0 auto;
    position: absolute;
    padding: 0px;
    width: 100vw;
    z-index: 10;
  }
  
  .mobileAlert div {
    display: block;
    margin: 0 auto;
    padding: 140px 25px 0 25px;
    text-align: center;
  }
  
  .continue {
    display: block;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    background-color: #7563c9;
    border: 0px;
    border-radius: 8px;
    color: #ffffff;
    min-width: 200px;
  }
  
  .mobileAlert .text {
    margin: 0 auto 20px auto;
  }

  .wrapper{
    margin-top: 0;
    padding: 0;
  }

  .datetime {
    margin-top: 20px;
  }

  .magritte,
  .hide,
  #p3,
  #p4 {
    display: none;
  }

  #p5 {
    display: block;
  }

  .result:nth-child(1) {
    margin-left: 0;
  }

  .scroll-tip {
    margin: 0;
    padding: 0;
  }
}